'use client';
import { useEffect, useState } from 'react';
import { AppContext } from './AppContext';


// type App = {
// };

// const initialApp: App = {
//     
// };

const AppContextProvider = ({ children }: any) => {
  // const [app, setApp] = useState<App>(initialApp);
  const [isLeftNavOpen, setIsLeftNavOpen] = useState<boolean>(false);
  const [isTutorialLeftNavOpen, setIsTutorialLeftNavOpen] =
    useState<boolean>(true);
  const [theme, setTheme] = useState<string>('');
  const [themeMode, setThemeMode] = useState<string>('');
  const [isSidebarLargeOpen, setIsSidebarLargeOpen] = useState(true)
  const [isSidebarSmallOpen, setIsSidebarSmallOpen] = useState(false)

  function isScreenSmall() {
    return window.innerWidth < 1024
  }

  function sidebarToggle() {
    if (isScreenSmall()) {
      setIsSidebarSmallOpen(s => !s)
    } else {
      setIsSidebarLargeOpen(l => !l)
    }
  }

  function sidebarClose() {
    if (isScreenSmall()) {
      setIsSidebarSmallOpen(false)
    } else {
      setIsSidebarLargeOpen(false)
    }
  }

  useEffect(() => {
    function sreenSizeHandle() {
      const handler = () => {
        if (!isScreenSmall()) setIsSidebarSmallOpen(false)
      }

      window.addEventListener("resize", handler)

      return () => {
        window.removeEventListener("resize", handler)
      }
    }
    sreenSizeHandle();
    const theme = localStorage.getItem('theme');
    if (theme) {
      setTheme(theme);
    }
    const themeMode = localStorage.getItem('theme-mode');
    if (themeMode) {
      setThemeMode(themeMode);
    }
  }, []);

  useEffect(() => {

    if (themeMode === 'auto') {
      const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDarkMode) {
        setTheme('dark')
      } else {
        setTheme('light')
      }
    } else if (themeMode === 'dark') {
      setTheme('dark')
    } else if (themeMode === 'light') {
      setTheme('light')
    }

    localStorage.setItem('theme-mode', themeMode)

  }, [themeMode]);

  useEffect(() => {
    const htmlElement = document?.querySelector('html');
    const bodyElement = document?.querySelector('body');

    // if (theme === 'dark') {
    //   htmlElement?.setAttribute('dark', '');
    // } else if (theme === 'light') {
      //   htmlElement?.removeAttribute('dark');
      // }
    if (theme) {
      htmlElement?.setAttribute('data-mantine-color-scheme', theme);
    }
    bodyElement?.classList.toggle('dark', theme === 'dark');
    bodyElement?.classList.toggle('light', theme === 'light');

    localStorage.setItem('theme', theme)
    localStorage.setItem('mantine-color-scheme-value', theme)

  }, [theme]);

  return (
    <AppContext.Provider
      value={{
        isLeftNavOpen,
        setIsLeftNavOpen,
        isTutorialLeftNavOpen,
        setIsTutorialLeftNavOpen,
        theme,
        // setTheme,
        themeMode,
        setThemeMode,
        isSidebarLargeOpen,
        isSidebarSmallOpen,
        sidebarToggle,
        sidebarClose,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
