import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/@next+third-parties@14.2.4_next@14.3.0-canary.11_react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/@next+third-parties@14.2.4_next@14.3.0-canary.11_react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/@next+third-parties@14.2.4_next@14.3.0-canary.11_react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["getSession","signOut"] */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/next-auth@5.0.0-beta.16_next@14.3.0-canary.11_nodemailer@6.9.14_react@18.3.1/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/next@14.3.0-canary.11_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/next@14.3.0-canary.11_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Roboto_Flex\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/app/Provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/app/theme.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/styles/ContentSection.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/styles/Modal.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/styles/Post.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/styles/common.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/styles/footer.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/react-loading-skeleton@3.4.0_react@18.3.1/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/ui/atoms/cookie-consent-banner/CookieConsentBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/ui/atoms/navigation-events/NavigationEvents.tsx");
