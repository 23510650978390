'use client'
import AppContextProvider from '@lib/context/app/AppContextProvider';
import AuthContextProvider from '@lib/context/auth/AuthContextProvider';
import { SkeletonTheme } from 'react-loading-skeleton';
import { SessionProvider } from 'next-auth/react';
import SearchContextProvider from '@lib/context/search/SearchContextProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';

export default function Provider({
  children,
  session,
}: {
  children: any;
  session: any;
}) {

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      })
  );

  return (
    <AppContextProvider>
      <SessionProvider session={session}>
        {/* <SessionProvider> */}
        {/* <SkeletonTheme baseColor="#dddddd" highlightColor="#858585"> */}
        {/* <SkeletonTheme baseColor="#202020" highlightColor="#444"> */}
        <SkeletonTheme>
          {/* <SkeletonTheme> */}
          <AuthContextProvider>
            <SearchContextProvider>
              <QueryClientProvider client={queryClient}>
                {children}
              </QueryClientProvider>
            </SearchContextProvider>
          </AuthContextProvider>
        </SkeletonTheme>
      </SessionProvider>
    </AppContextProvider>
  );
}
