'use client'
import { useEffect, useState } from "react";
import Link from "next/link";
import { gtag, initDataLayer, install } from 'ga-gtag';


export function getLocalStorage(key: string, defaultValue: any) {
  const stickyValue = localStorage.getItem(key);

  return stickyValue !== null && stickyValue !== "undefined"
    ? JSON.parse(stickyValue)
    : defaultValue;
}

export function setLocalStorage(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export default function CookieConsentBanner() {

  const [cookieConsent, setCookieConsent] = useState('');

  useEffect(() => {
    const storedConsent = getLocalStorage("cookie_consent", 'pending');
    setCookieConsent(storedConsent);
  }, [])


  useEffect(() => {
    // const newValue = cookieConsent === 'accepted-all' || cookieConsent === 'accepted-necessary' ? "granted" : "denied";

    // Replace gtag function with your actual Google Analytics function
    // https://support.google.com/analytics/answer/12334711
    if (cookieConsent !== 'pending') { // Update gtag only after valid consent is obtained
      // gtag("consent", "update", {
        // analytics_storage: cookieConsent,
      // });
    }
    if (cookieConsent !== '') {
      setLocalStorage("cookie_consent", cookieConsent);
    }
  }, [cookieConsent]);

  return (
    <div
      style={{
        display: cookieConsent === '' || cookieConsent === 'accepted-all' || cookieConsent === 'accepted-necessary' || cookieConsent === 'denied' ? "none" : "flex",
        flexDirection: "column",
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 20,
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "24px",
        border: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <p style={{ maxWidth: "calc(100% - 32px)", fontSize: "14px", lineHeight: "1.5", color: "#333" }}>
        This website uses cookies to enhance your browsing experience, analyze
        site traffic, and serve better user experiences. By continuing to use
        this site, you consent to our use of cookies. Learn more in our{" "}
        <Link style={{ fontWeight: "600", color: "#8A2BE2" }} href="/privacy">
          cookie policy
        </Link>
      </p>

      <div style={{ display: "flex", gap: "8px" }}>
        <div style={{ marginRight: "16px", display: "flex", alignItems: "center", gap: "8px" }}>
          <button
            onClick={() => setCookieConsent('accepted-all')}
            type="button"
            style={{
              borderRadius: "4px",
              backgroundColor: "#333",
              color: "white",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "600",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
            }}
          >
            Accept all 🍪
          </button>
          <button
            onClick={() => setCookieConsent('denied')}
            type="button"
            style={{ fontSize: "14px", fontWeight: "600", lineHeight: "1.5", color: "#333", cursor: "pointer" }}
          >
            Reject all
          </button>
        </div>
      </div>
    </div>
  );
}
