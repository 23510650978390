'use client';
import React, { useState } from 'react';
import { AuthContext } from './AuthContext';
// import { SessionProvider } from 'next-auth/react';

type Auth = {
  accessToken: string;
  role: string;
  user: any;
};
const initialAuth: Auth = {
  accessToken: '',
  role: '',
  user: {},
};
const AuthContextProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<Auth>(initialAuth);
  let initialPersistedValue;
  if (typeof window !== 'undefined') {
    initialPersistedValue = localStorage.getItem('persist');
  }
  const [persist, setPersist] = useState(
    initialPersistedValue ? JSON.parse(initialPersistedValue) : false,
  );
  return (
    // <SessionProvider>
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>
      {children}
    </AuthContext.Provider>
    // </SessionProvider>
  );
};

export default AuthContextProvider;