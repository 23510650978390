'use client';
import { createContext } from 'react';

type Auth = {
  accessToken: string;
  role: string;
  user: any;
};

type AuthContextType = {
  auth: Auth;
  setAuth: React.Dispatch<React.SetStateAction<Auth>>;
};

// export const AuthContext = createContext<AuthContextType | undefined>(
export const AuthContext = createContext<any | undefined>(undefined);
