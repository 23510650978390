'use client';
import React, { useEffect, useState } from 'react';
import { SearchContext } from './SearchContext';

const SearchContextProvider = ({ children }: any) => {
  
  const [q, setQ] = useState<string | null>('');

  return (
    <SearchContext.Provider
      value={{
        q,
        setQ,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
